<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3>Manage {{ module_name }}</h3>
                    </template>
                    <template v-slot:end>
                        <!-- <router-link v-if="permissions[16]" to="/companies/manage-companies">
                            <Button v-tooltip.top="'Manage Companies'" label="Manage Companies" icon="pi pi-arrow-up-right" class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link> -->
                    </template>
                </Toolbar>

                <div class="grid">
                    <div class="col-12 lg:col-6 sm:col-12 md:col-12">
                        <Panel class="mb-3 full-width" :header="'Paypal'" :toggleable="true" style="float: left">
                            <div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left">
                                    <label>Client ID#</label>
                                    <InputText id="payapl_client_id" placeholder="Client ID" v-model="formPaypal.client_id" :value="formPaypal.client_id" type="text" class="full-width" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left">
                                    <label>Client Secret#</label>
                                    <InputText id="paypal_client_secret" placeholder="Client Secret" v-model="formPaypal.client_secret" :value="formPaypal.client_secret" type="text" class="full-width" />
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2 mt-3" style="float: left">
                                    <Button
                                        type="submit"
                                        label="Save"
                                        :loading="isSaving"
                                        v-if="checkPermission(moduleId, 'insert')"
                                        icon="pi pi-check"
                                        @click="saveSettings(1)"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-lg p-button-success mr-2"
                                    />
                                </div>
                            </div>
                        </Panel>
                    </div>
                    <div class="col-12 lg:col-6 sm:col-12 md:col-12">
                        <Panel class="mb-3 full-width" :header="'Stripe'" :toggleable="true" style="float: left">
                            <div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left">
                                    <label>Publishable key</label>
                                    <InputText id="stripe_publishable_key" placeholder="Publishable Key" v-model="formStripe.publishable_key" :value="formStripe.publishable_key" type="text" class="full-width" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left">
                                    <label>Secret key</label>
                                    <InputText id="stripe_secret_key" placeholder="Secret Key" v-model="formStripe.secret_key" :value="formStripe.secret_key" type="text" class="full-width" />
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2 mt-3" style="float: left">
                                    <Button
                                        type="submit"
                                        label="Save"
                                        :loading="isSaving"
                                        v-if="checkPermission(moduleId, 'insert')"
                                        icon="pi pi-check"
                                        @click="saveSettings(2)"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-lg p-button-success mr-2"
                                    />
                                    <!-- <Button v-tooltip.top="'Save'" :label="showBtnLabels ? 'Save' : ''" @click="saveSettings(2)" icon="pi pi-check" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />  -->
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <PaymentGatewaysForm v-on:formClose="closeForm" v-if="showForm && (checkPermission(moduleId, 'insert') || checkPermission(moduleId, 'update'))" :recordId="selectedRecordId" :showForm="true" />
        <ModuleHelp v-on:closeHelp="showHelp = false" v-if="showHelp && checkPermission(moduleId, 'view')" :moduleId="moduleId" />
    </div>
</template>
<script>
import PaymentGatewaysForm from '../../components/SystemSettings/PaymentGatewaysForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            formPaypal: new this.Form({
                id: '',
                payment_gateway_id: 1,
                client_id: '',
                client_secret: '',
            }),
            formStripe: new this.Form({
                id: '',
                payment_gateway_id: 2,
                publishable_key: '',
                secret_key: '',
            }),
            module_name: 'Payment Gateways Settings',
            actions: [],
            moduleId: 63,
            showHelp: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            selectedRecordId: 0,
            saveAndClose: false,
        };
    },
    components: {
        PaymentGatewaysForm,
        ModuleHelp,
    },
    created() {
        if (this.checkPermission(this.moduleId, 'update')) {
            this.actions.push({
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.getClickedRecord('update');
                },
            });
        }
        if (this.checkPermission(this.moduleId, 'delete')) {
            this.actions.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                },
            });
        }
    },
    computed: {},
    mounted() {
        this.getRecords();
    },
    methods: {
        async saveSettings($paymentGateWay) {
            var Vthis = this;
            Vthis.isSaving = true;
            let form = this.formPaypal;
            if ($paymentGateWay === 1) {
                form = this.formPaypal;
            } else if ($paymentGateWay === 2) {
                form = this.formStripe;
            }
            await form
                .post(Vthis.$baseurl + 'api/savePaymentGatewaySettings', form)
                .then((response) => {
                    let responseStatus = Vthis.printResponseResult(response);
                    if (responseStatus == 200) {
                        if (Vthis.saveAndClose) {
                            setTimeout(() => {
                                Vthis.closeForm();
                            }, 1000);
                            Vthis.saveAndClose = false;
                        }
                    }
                })
                .catch((error) => {
                    Vthis.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.isSaving = false;
                });
        },

        getRecords() {
            this.loading = true;
            this.axios
                .post('getAllPaymentGatewaysSettings')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.records = response.data;
                        if (this.records.length) {
                            this.records.forEach((record) => {
                                console.log(record);
                                if (record.payment_gateway_id === 1) {
                                    this.formPaypal.fill(record);
                                }
                                if (record.payment_gateway_id === 2) {
                                    this.formStripe.fill(record);
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        isRTL() {
            return this.$appState.RTL;
        },
    },
    watch: {},
};
</script>
 
